import React from 'react';
import styled from 'styled-components';
import checkmarkCircle from '../../images/checkmark-circle-blue.png';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const ThankyouWrapper = styled.div`
  margin: 0;

  li {
    background: url(${checkmarkCircle}) no-repeat 2px 2px/15px transparent;
    list-style-type: none;
    margin: 0;
    padding: 0px 0px 10px 24px;
    vertical-align: middle;
  }
`;

const Intro = styled.section`
  min-height: 100px;
  background-color: #d7edf7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 0;
  min-height: 120px;
  p {
    font-size: 3em;
    text-align: center;
    color: #1a739f;
  }
  a {
    color: #337ab7;
    text-decoration: none;
  }
`;

const Header = styled.header`
  background: #f2f2f2;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 3em;
    color: #b30000;
    text-align: center;
    margin: 0;
  }
`;

const Content = styled.section``;

const MidContent = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    width: 264px;
  }

  div {
    width: 70%;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: space-around;
  padding-left: 40px;

  > div {
    width: 45%;
  }

  p,
  h4,
  img {
  }

  @media (max-width: 900px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`;

const BottomSub = styled.div`
  display: flex;
  img {
    width: 150px;
    height: 150px;
  }
  div {
    width: 95%;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Text = styled.p`
  font-style: ${props => props.secondary && 'italic'};
  span {
    font-weight: 700;
  }
`;

const DLendThankyou = () => (
  <ThankyouWrapper>
    <Intro>
      <p>
        Call Now! <a href="tel:8552986954">1-855-298-6954</a>
      </p>
    </Intro>
    <Header>
      <h1>Congratulations! We've Found A Match!</h1>
    </Header>
    <Content>
      <MidContent>
        <img
          src="https://s3.amazonaws.com/sc-dba.com/lmbx-ty/images/loan-depot-logo.png"
          alt="Lendilio"
        />
        <div>
          <h2>Lower Payments. Consolidate Debt. Pull Cash Out</h2>
          <Text>
            loanDepot matches borrowers through technology and customer care
            with the credit they need to fuel their lives.
          </Text>
          <Text secondary>
            If you would like to speak with someone about your request
            immediately, <span>please call</span>
            <a href="tel:8552986954">1-855-298-6954</a>.
          </Text>
        </div>
      </MidContent>
      <hr />
      <BottomContent>
        <div>
          <h4>
            loanDepot matches borrowers through technology and customer care
            with the credit they need to fuel their lives.
          </h4>
          <ul>
            <li>$165+ Billion funded since 2010</li>
            <li>Competitive Rates</li>
            <li>Proudly serving Veterans</li>
            <li>Innovator of Financial Technology</li>
          </ul>
        </div>

        <div>
          <h4>Refinance Your Mortgage With Confidence</h4>
          <BottomSub>
            <img
              src="https://s3-us-west-1.amazonaws.com/offers.suitedconnector.com/thankyou/elend/testimonial.png"
              alt="testimonial"
            />
            <div>
              <Text>
                "loanDepot took the time to explain in simple language all of
                the terms for my loan. I could not ask for anything more, they
                did exactly what they said they would and I have had no
                problems."
              </Text>
              <Text>
                <span>Jenifer P.</span>
                <br />
                California
              </Text>
            </div>
          </BottomSub>
          <ul>
            <li>
              <a
                href="https://www.zillow.com/lender-profile/loanDepot/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                Read reviews of loanDepot’s 5 star rating
              </a>
            </li>
          </ul>
        </div>
      </BottomContent>
      <hr />
    </Content>
  </ThankyouWrapper>
);

export default withThankyouWrapper(DLendThankyou);
